var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"2024-11-8_15:43:6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig?.sentry?.dsn) {
    const { dsn, version: release, tracesSampleRate, environment } = publicRuntimeConfig.sentry;
    Sentry.init({
        dsn,
        environment,
        release,
        tracesSampleRate,
        integrations: [
            Sentry.browserProfilingIntegration(),
        ],
    });
    Sentry.setTag("layer", "next-front-end");
}
